<template>
  <div>
    <v-tabs
      v-model="currentTab"
      grow
    >
      <v-tab>Internal Sensors</v-tab>
      <v-tab>External Sensors</v-tab>
    </v-tabs>
    <loading v-if="$apollo.loading" />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              @click="changeOrder('NAME')"
            >
              Name
              <sorting-arrows :direction="column === 'NAME' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
            >
              Value
            </th>
            <th
              class="text-center text-uppercase"
            >
              Last Update
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('UPDATE_RATE')"
            >
              Update Rate
              <sorting-arrows :direction="column === 'UPDATE_RATE' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
            >
              History
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="allSensors && allSensors.length == 0">
            <td class="text-center">
              <h3>
                <v-icon size="20">
                  {{ icons.mdiCancel }}
                </v-icon>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-for="item in allSensors"
            v-else
            :key="item.id"
          >
            <td>
              <div v-if="item.name">
                {{ getName(item.name) }}
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <v-icon
                v-if="(item.name === 'Tilt' || item.name === 'GPS') && item.alert_sent && item.device.status === 'online'"
                color="warning"
                class="pb-1"
                size="17"
              >
                {{ icons.mdiAlert }}
              </v-icon>
              {{ getLastValue(item) }}
            </td>
            <td class="text-center">
              <div v-if="item.sensor_data && item.sensor_data.data.length > 0">
                <v-icon
                  v-if="checkLastPing(item)"
                  color="warning"
                  class="pb-1"
                  size="17"
                >
                  {{ icons.mdiAlert }}
                </v-icon>
                {{ moment.unix(item.sensor_data.data[0].last_update).format("YYYY-MM-DD HH:mm") }}
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.update_rate && item.name !== 'Battery Capacity'">
                <v-menu
                  v-if="role === 'superadmin' && item.name !== 'Battery Capacity' && item.name !== 'Solar Input Average'"
                  open-on-hover
                  transition="scale-transition"
                  allow-overflow
                  offset-x
                  nudge-width="50px"
                  close-delay="100"
                  rounded
                  content-class="bg-pencil"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      @click="openUpdateModal(item)"
                    >
                      {{ item.update_rate }} min
                    </span>
                  </template>
                  <v-icon
                    style="overflow: hidden;"
                    size="20"
                    @click="openUpdateModal(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-menu>
                <span
                  v-else
                  style="opacity: 0.65"
                >{{ item.update_rate }} min</span>
              </div>
              <div v-else>
                <span style="opacity: 0.65"> {{ '/' }} </span>
              </div>
            </td>
            <td class="text-center">
              <router-link
                :disabled="item.name == 'Battery Capacity'"
                :to="`/devices/${$route.params.id}/sensor/${item.id}`"
                icon
                small
              >
                <v-icon size="20">
                  {{ icons.mdiFileClockOutline }}
                </v-icon>
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- <pagination
      v-if="allSensors.length > 0"
      :count="countPages"
      :page.sync="currentPage"
    /> -->

    <v-dialog
      v-model="isDialogVisible"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedSensor ? selectedSensor.name : '' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newUpdateRate"
                  dense
                  label="Update rate"
                  type="number"
                  min="0"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="() => {
              isDialogVisible = false
              newUpdateRate = null
            }"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!isValid"
            color="success"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiViewListOutline,
  mdiCancel,
  mdiFileClockOutline,
  mdiAlert,
} from '@mdi/js'
// import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
// import _isNil from 'lodash/isNil'
import Loading from '../Loading.vue'
import SortingArrows from '../SortingArrows.vue'
// import axios from '@axios'
import moment from 'moment'

export default {
  name: 'SensorsTable',
  components: {
    // Pagination,
    Loading,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    sensors: {
      query: gql`
        query (
          $device_id: ID!
          $type: String
          $page: Int!
          $search: String
          $orderBy: [QuerySensorsOrderByOrderByClause!]
        ) {
          sensors(device_id: $device_id, type: $type, page: $page, search: $search, orderBy: $orderBy) {
            data {
              id
              name
              last_value
              type
              update_rate
              measuring_unit
              alert_sent
              sensor_data {
                data {
                  id
                  last_update
                }
              }
              device {
                id
                status
              }
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          type: this.currentTab === 0 ? 'internal' : 'external',
          device_id: this.$route.params.id,
          orderBy: [
            {
              column: this.column ?? 'NAME',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
        }
      },
      skip() {
        return !this.$route.params.id
      },
      result(result) {
        this.allSensors = result.data.sensors.data.filter(sensor => {
          if (
            sensor.name !== 'Battery (mA)' &&
            sensor.name !== 'Battery (v)' &&
            sensor.name !== 'Solar Input (mA)' &&
            sensor.name !== 'Solar Input (v)'
          )
            return sensor
        })
        this.countPages = result.data.sensors.paginatorInfo.lastPage
        this.currentPage = result.data.sensors.paginatorInfo.currentPage
      },
      errorPolicy: 'all',
      error(e) {
        if (e.networkError) this.$toastr.e('Network Error')
        else this.$toastr.e('Server Error')
      },
    },
  },
  setup(props) {
    const role = localStorage.getItem('userAbility')
    const currentPage = ref(1)
    const countPages = ref(1)
    const searchProp = ref(props.search)
    const showModal = ref(false)
    const showDeleteModal = ref(false)
    const column = ref('NAME')
    const order = ref('ASC')
    const allSensors = ref([])
    const selectedSensor = ref(null)
    const currentTab = ref(0)
    const isDialogVisible = ref(false)
    const newUpdateRate = ref(null)

    return {
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiViewListOutline,
        mdiCancel,
        mdiFileClockOutline,
        mdiAlert,
      },
      role,
      allSensors,
      currentPage,
      countPages,
      searchProp,
      showModal,
      showDeleteModal,
      column,
      order,
      currentTab,
      isDialogVisible,
      selectedSensor,
      newUpdateRate,
    }
  },
  methods: {
    openUpdateModal(item) {
      this.isDialogVisible = true
      this.selectedSensor = item
      this.newUpdateRate = item.update_rate
    },
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
    async save() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!, $update_rate: Int!) {
              updateSensor(id: $id, update_rate: $update_rate) {
                id
              }
            }
          `,
          variables: {
            update_rate: parseInt(this.newUpdateRate, 10),
            id: this.selectedSensor.id,
          },
        })
        this.$toastr.s('Update rate successfully updated!')
        this.$apollo.queries.sensors.refetch()
      } catch (err) {
        this.$toastr.e(err.networkError ? 'Network Error' : err.graphQLErrors)
      } finally {
        this.isDialogVisible = false
        this.selectedSensor = null
      }
    },
    getLastValue(sensor) {
      if (!sensor.last_value) return '/'

      if (sensor.name === 'Air Quality') {
        const value = JSON.parse(sensor.last_value)

        return `${value[0]}  µg/m³, ${value[1]} µg/m³, ${value[2]} µg/m³, ${value[3]} µg/m³`
      }

      if (sensor.name === 'GPS') {
        const value = JSON.parse(sensor.last_value)

        return `${value.lat}, ${value.long}`
      }

      if (sensor.name === 'Tilt') {
        const sensor_value = JSON.parse(sensor.last_value)

        return `Pitch: ${sensor_value.pitch}${sensor.measuring_unit}, Roll: ${sensor_value.roll}${sensor.measuring_unit}`
      }

      let value = sensor.last_value

      if (sensor.name === 'Solar Input' || sensor.name === 'Solar Input Average')
        value = Math.round(sensor.last_value * 1000) / 1000

      return `${value}${sensor.measuring_unit}`
    },
    checkLastPing(sensor) {
      if (sensor.device.status === 'online' && sensor.name !== 'Battery Capacity') {
        let last_ping = sensor.sensor_data.data[0].last_update
        const now = new Date()
        const last_ping_added_day = moment.unix(last_ping).add(1, 'day')

        if (now > last_ping_added_day) return true
      }

      return false
    },
    getName(name) {
      if (name == 'Solar Input Average') return "Solar Input Yesterday's Average"
      if (name == 'Air Quality') return 'Air Quality (PM1, PM2.5, PM4, PM10)'

      return name
    },
  },
  computed: {
    isValid() {
      if (this.newUpdateRate === null) return false
      if (this.newUpdateRate === undefined || this.newUpdateRate <= 0 || this.newUpdateRate > 999999999) return false
      return true
    },
  },
}
</script>

<style scoped>
.bg-pencil {
  background-color: #f0f0f0 !important;
}
.bg-pencil:hover {
  background-color: #fafafa !important;
}
</style>
