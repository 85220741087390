<template>
  <div class="my-3">
    <page-title :title="(device ? device.id_imei + ' - ' : '') + 'Device\'s sensors'" />
    <v-row class="px-3">
      <v-btn
        color="primary"
        dark
        class="mb-1"
        @click="$router.push('/availability')"
      >
        <v-icon size="20">
          {{ icons.mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
        ></v-text-field>
      </div>
    </v-row>
    <sensors-table
      ref="sensorsTable"
      :search="search"
      @change="refetchData"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiArrowLeft } from '@mdi/js'
import SensorsTable from '@/views/components/sensors/SensorsTable.vue'
import gql from 'graphql-tag'
import PageTitle from './components/PageTitle.vue'

export default {
  components: {
    SensorsTable,
    PageTitle,
  },

  apollo: {
    device: {
      query: gql`
        query ($id: ID!) {
          device(id: $id) {
            id
            id_imei
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route.params.id
      },
      errorPolicy: 'all',
      error(e) {
        if (e.networkError) this.$toastr.e('Network Error')
        else this.$toastr.e('Server Error')
      },
    },
  },
  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
        mdiArrowLeft,
      },
      role,
      search,
      showModal,
    }
  },

  methods: {
    refetchData() {
      this.$refs.sensorsTable.getSensors()
    },
  },
}
</script>
